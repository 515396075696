// https://netacci.hashnode.dev/fileimage-upload-using-multer-formik-expressjs-reactjs-and-mongodb
// https://stackoverflow.com/questions/63484798/upload-a-file-in-formik-multistep
// https://www.youtube.com/watch?v=BPUgM1Ig4Po
// https://blog.logrocket.com/using-filereader-api-preview-images-react/

import * as React from 'react';
import { useFormik } from 'formik';
import axios from 'axios';

import Layout from '../components/layout';
import Seo from '../components/seo';

import * as style from '../components/styles/profiler.module.css';

export default function SDProfiler() {
  const formik = useFormik({
    initialValues: {
      requestor: '',
      email: '',
      request: '',
      file: '',
    },
    onSubmit: (values) => {
      // values.preventDefault();
      // console.log(values);
      const fd = new FormData();
      fd.append('requestor', values.requestor);
      fd.append('email', values.email);
      fd.append('request', values.request);
      fd.append('file', values.file);

      axios.post(
        'https://api.silentdistortion.com/api/profile',
        fd,
      )
        .then(res =>{
          formik.resetForm();
          alert('Upload successful.  We should get your profile to you shortly by mail.');
          console.log(res);
        })
        .catch(err =>{
          alert('There seems to be an error.  Please contact us for more information.')
        });
    },
  });

  return (
    <Layout>
      <Seo title="Request a Profile" />
      <div className={style.formmain}>
        <h4 className={style.instructions}>
          Enter the information below to recieve a profile by email.
          Please include a csv or xlsx file with the data.
          If you have sensitive data, please contact us for special instructions.
        </h4>
        <form name="profile_file" className={style.form} onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <input
            type="text"
            className={style.sdsmall}
            placeholder="Your Name"
            name="requestor"
            onChange={formik.handleChange}
            value={formik.values.requestor}
          />
          <input
            type="email"
            className={style.sdsmall}
            placeholder="Your Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <input
            type="textarea"
            className={style.sdlarge} 
            placeholder="Enter special requests here..."
            name="request"
            onChange={formik.handleChange}
            value={formik.values.request}
          />
          <input
            type="file"
            className={style.sdfile}
            name="file"
            onChange={(e) => formik.setFieldValue('file', e.currentTarget.files[0])}
            // value={formik.values.file}
          />
          <button type="submit" className={style.sdbutton} disabled={formik.isSubmitting}>Submit</button>
        </form>
      </div>  
    </Layout>
  );
}
